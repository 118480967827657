import React from 'react';

type TextInputProps = JSX.IntrinsicElements['input'] & {
  className?: string;
  overrideBaseClassnames?: boolean;
  label?: string;
  error?: string;
  formatValue?: (value: string) => string;
  callbackOnChange?: (name?: string) => void;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
};

/**
 * The callbackOnChange prop allows you to pass a function that will be called
 * whenever the input value changes.
 * Note: callbackOnChange is not the same as react-hook-form clearErrors,
 * but you can pass a function that calls clearErrors internally.
 */
export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const {
    label,
    type,
    error,
    name,
    className,
    overrideBaseClassnames,
    formatValue,
    callbackOnChange,
    iconLeft,
    iconRight,
    ...rest
  } = props;

  const baseClassNames = `${className} text-color focus:border-primary focus:dark:border-[#7AC2B9] outline-none border-blue-lighter dark:border-primary-darker bg-color-secondary focus:border-primary placeholder:text-neutral-dark h-10 w-full rounded-xl border-2 px-4 text-base hover:shadow-grey-sm hover:border-blue-dark hover:dark:shadow-dark-sm hover:dark:border-primary disabled:border-[#CBD3D6] disabled:text-neutral-dark disabled:bg-[#F8F9FA] disabled:dark:border-[#85979E] disabled:dark:bg-dark-dark`;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (formatValue) {
      e.target.value = formatValue(value);
    }
    if (callbackOnChange && name) {
      callbackOnChange(name);
    }
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <div className="relative flex w-full flex-col gap-2">
      {label && <p className="font-bold">{label}</p>}
      <div className="relative flex items-center">
        {iconLeft && <div className="absolute left-1 top-1/2 -translate-y-1/2 p-2">{iconLeft}</div>}

        <input
          ref={ref}
          name={name}
          type={type}
          className={`${overrideBaseClassnames ? className : baseClassNames} ${!!error && 'dark:border-error-light hover:dark:border-error-light border-[#E76F51] hover:border-[#E76F51]'} ${iconLeft ? 'pl-10' : ''}`}
          aria-invalid={!!error}
          {...rest}
          onChange={handleChange}
          style={{
            paddingRight: iconRight ? '2.5rem' : undefined,
          }}
        />

        {iconRight && <div className="absolute right-4 z-10">{iconRight}</div>}
      </div>
      {error && (
        <div id={`${name}-error`} className="ml-2 flex flex-col">
          <p className="text-error dark:text-error-light text-xs">{error}</p>
        </div>
      )}
    </div>
  );
});

TextInput.displayName = 'TextInput';

export default TextInput;
