import React from 'react';

type NumberInputProps = JSX.IntrinsicElements['input'] & {
  className?: string;
  overrideBaseClassnames?: boolean;
  label?: string;
  error?: string;
};

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>((props, ref) => {
  const { label, error, name, className, overrideBaseClassnames, ...rest } = props;

  const baseClassNames = `${className} text-dark border-neutral bg-light focus:border-primary placeholder:text-neutral-dark h-10 w-full rounded-xl border-2 px-4 text-base outline-none`;

  return (
    <div className="relative flex w-full flex-col gap-2">
      {label && <p className="ml-2">{label}</p>}
      <div className="relative">
        <input
          ref={ref}
          name={name}
          type="number"
          step="0.01"
          min="0"
          className={`${overrideBaseClassnames ? className : baseClassNames} ${!!error && 'border-error'}`}
          aria-invalid={!!error}
          {...rest}
        />
      </div>
      {error && (
        <div id={`${name}-error`} className="ml-2 flex flex-col">
          <p className="text-error text-xs">{error}</p>
        </div>
      )}
    </div>
  );
});

NumberInput.displayName = 'NumberInput';

export default NumberInput;
