import { REFERRER_COOKIE_NAME } from '../utils';

const useReferrerSourceCookie = () => {
  const allCookies = document.cookie.split(';');
  const refCookie = allCookies
    .map((x) => x.trim()) // Otherwise strings have a leading space
    .find((x) => x.startsWith(REFERRER_COOKIE_NAME));
  const split = refCookie?.split('=');

  if (!split || !split[1]) return undefined;

  return split[1];
};

export default useReferrerSourceCookie;
