import useSWR from 'swr';
import { PropertyRating } from '../../models/properties';
import { getPropertyEpc } from '../../network/properties';

const useProperty = ({ postcode, addressLine1 }: { postcode: string; addressLine1: string }) => {
  const { data, error, mutate, isLoading } = useSWR<PropertyRating>(
    `property-epc-data-${postcode}-${addressLine1}`,
    async () => await getPropertyEpc({ postcode, addressLine1 })
  );

  return {
    epc: data,
    error,
    mutate,
    isLoading,
  };
};

export default useProperty;
