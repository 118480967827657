import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Children } from '../utils';

interface ThemeContextProps {
  theme: string;
  setTheme: Dispatch<SetStateAction<string>>;
}

const defaultThemeContext: ThemeContextProps = {
  theme: 'light',
  setTheme: () => {},
};

const ThemeContext = createContext<ThemeContextProps>(defaultThemeContext);

export const ThemeProvider = ({ children }: { children: Children }) => {
  const [theme, setTheme] = useState(() => {
    const userPreferredTheme = localStorage.getItem('theme');
    return userPreferredTheme || getSystemTheme();
  });

  useEffect(() => {
    const applyThemeToDocument = () => {
      const d = document.documentElement;
      d.classList.remove('light', 'dark');
      d.classList.add(theme);
      localStorage.setItem('theme', theme);
    };

    applyThemeToDocument();
  }, [theme]);

  useEffect(() => {
    const handleSystemThemeChange = (e: MediaQueryListEvent) => {
      if (!localStorage.getItem('theme')) {
        setTheme(getSystemTheme(e));
      }
    };

    const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQueryList.addEventListener('change', handleSystemThemeChange);

    return () => {
      mediaQueryList.removeEventListener('change', handleSystemThemeChange);
    };
  }, []);

  const contextValue: ThemeContextProps = { theme, setTheme };

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};

export const useTheme = (): ThemeContextProps => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

const getSystemTheme = (e?: MediaQueryListEvent | MediaQueryList) => {
  const isDark = (e && e.matches) || window.matchMedia('(prefers-color-scheme: dark)').matches;
  return isDark ? 'dark' : 'light';
};
