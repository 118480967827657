import React from 'react';
import { CiDroplet } from 'react-icons/ci';

const EpcRatingArrow = ({
  rating,
  propertyRating,
  className,
}: {
  rating: string;
  propertyRating: string;
  className: string;
}) => {
  return (
    <>
      <div
        className={`relative flex size-8 items-center justify-center ${
          propertyRating === rating ? 'visible' : 'invisible'
        }`}
      >
        <CiDroplet className={`${className} absolute -left-4 -top-2.5 -rotate-90`} size={50} />
        <p className="dark:text-light text-dark text-xl font-bold">{`${rating}`}</p>
      </div>
    </>
  );
};

export default EpcRatingArrow;
