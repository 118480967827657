import useSWR from 'swr';
import { PropertyOwnerStatuses } from '../models/properties';
import { getPropertyOwnerStatuses } from '../network/properties';
import { Option } from '../utils/generic';

const usePropertyOwnerStatuses = () => {
  const { data, error, mutate, isLoading } = useSWR<PropertyOwnerStatuses[]>(
    'property-owner-statuses',
    async () => await getPropertyOwnerStatuses()
  );

  return {
    statuses: data && data,
    statusOptions:
      data &&
      (data
        .map((item) => (item.isActive ? { value: item.id, label: item.name } : undefined))
        .filter((item) => item !== undefined) as Option<string>[]),
    error,
    mutate,
    isLoading,
  };
};

export default usePropertyOwnerStatuses;
