import React from 'react';
import EpcGraphBar from './EpcGraphBar';
import EpcRatingArrow from './EpcRatingArrow';

const EpcGraph = ({ propertyRating }: { propertyRating?: string }) => {
  return (
    <div className="flex flex-row gap-2">
      <div className="flex w-full flex-1 flex-col gap-0.5">
        <EpcGraphBar rating="A" className="bg-epc-a w-[25%]" />
        <EpcGraphBar rating="B" className="bg-epc-b w-[37.5%]" />
        <EpcGraphBar rating="C" className="bg-epc-c w-[50%]" />
        <EpcGraphBar rating="D" className="bg-epc-d w-[62.5%]" />
        <EpcGraphBar rating="E" className="bg-epc-e w-[75%]" />
        <EpcGraphBar rating="F" className="bg-epc-f w-[87.5%]" />
        <EpcGraphBar rating="G" className="bg-epc-g w-full" />
      </div>
      <div className="flex w-12 flex-col gap-0.5 pl-4">
        {propertyRating && (
          <>
            <EpcRatingArrow rating="A" propertyRating={propertyRating} className="text-epc-a" />
            <EpcRatingArrow rating="B" propertyRating={propertyRating} className="text-epc-b" />
            <EpcRatingArrow rating="C" propertyRating={propertyRating} className="text-epc-c" />
            <EpcRatingArrow rating="D" propertyRating={propertyRating} className="text-epc-d" />
            <EpcRatingArrow rating="E" propertyRating={propertyRating} className="text-epc-e" />
            <EpcRatingArrow rating="F" propertyRating={propertyRating} className="text-epc-f" />
            <EpcRatingArrow rating="G" propertyRating={propertyRating} className="text-epc-g" />
          </>
        )}
      </div>
    </div>
  );
};

export default EpcGraph;
