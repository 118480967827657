import React from 'react';

const EpcGraphBar = ({ rating, className }: { rating: string; className: string }) => {
  return (
    <div className={`flex h-8 flex-row items-center justify-end rounded-tr-[8px] px-2 ${className}`}>
      <p className="text-light-dark text-2xl font-bold">{rating}</p>
    </div>
  );
};

export default EpcGraphBar;
