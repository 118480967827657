import { useSearchParams } from 'react-router-dom';
import { REFERRER_COOKIE_NAME, REFERRER_URL_PARAM } from '../utils';
import { Children } from '../utils/generic';

export const ReferrerCookieHandler = ({ children }: { children: Children }) => {
  const rootDomain = import.meta.env.VITE_ROOT_DOMAIN;

  const [params] = useSearchParams();
  const refSrcValue = params.get(REFERRER_URL_PARAM) ?? undefined;

  if (refSrcValue !== undefined) {
    document.cookie = `${REFERRER_COOKIE_NAME}=${refSrcValue}; path=/; domain=.${rootDomain}; SameSite=Strict`;
  }

  return children;
};
