import React, { useState } from 'react';
import { BiHide, BiShow } from 'react-icons/bi';

type SensitiveTextInputProps = JSX.IntrinsicElements['input'] & {
  label?: string;
  error?: string;
  callbackOnChange?: (name?: string) => void;
};

export const SensitiveTextInput = React.forwardRef<HTMLInputElement, SensitiveTextInputProps>((props, ref) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { label, error, name, className, callbackOnChange, ...rest } = props;

  const baseClassNames = `${className} text-color focus:border-primary focus:dark:border-[#7AC2B9] outline-none border-blue-lighter dark:border-primary-darker bg-color-secondary focus:border-primary placeholder:text-neutral-dark h-10 w-full rounded-xl border-2 px-4 text-base hover:shadow-grey-sm hover:border-blue-dark hover:dark:shadow-dark-sm hover:dark:border-primary`;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (callbackOnChange && name) {
      callbackOnChange(name);
    }
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <div className="relative flex w-full flex-col gap-2">
      {label && <p className="font-bold">{label}</p>}
      <div className="relative flex items-center">
        <input
          ref={ref}
          name={name}
          type={showPassword ? 'text' : 'password'}
          className={`${baseClassNames} pr-8 ${!!error && 'dark:border-error-light hover:dark:border-error-light border-[#E76F51] hover:border-[#E76F51]'}`}
          aria-invalid={!!error}
          {...rest}
          onChange={handleChange}
        />

        <button
          type="button"
          className="absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer p-2"
          onClick={() => setShowPassword(!showPassword)}
        >
          {!showPassword ? <BiHide className="text-neutral-dark" /> : <BiShow className="text-primary" />}
        </button>
      </div>
      {error && (
        <div id={`${name}-error`} className="ml-2 flex flex-col">
          <p className="text-error dark:text-error-light text-xs">{error}</p>
        </div>
      )}
    </div>
  );
});

SensitiveTextInput.displayName = 'SensitiveTextInput';

export default SensitiveTextInput;
