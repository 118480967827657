import React, { Component, ReactNode } from 'react';
import { api } from '@epcbuilder/lib/utils';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false };
  }
  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    this.handleError(error);
  }

  async handleError(error: Error) {
    try {
      await api.logError(error);
    } catch (e) {
      console.log('Error logging error', JSON.stringify(e));
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div id="ErrorBoundary">
          <h1>Something went wrong</h1>
          <button type="button" onClick={() => this.setState({ hasError: false })}>
            Try again?
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
