import React, { useState } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Button } from './Buttons';

const CookieModal = ({ marketingUrl }: { marketingUrl?: string }) => {
  const [acceptedCookieExists, setAcceptedCookieExists] = useState<string | undefined>(Cookies.get('accepts_cookies'));

  const location = useLocation();
  const isOnPrivacyPage = location.pathname.indexOf('/privacy') !== -1;
  const rootDomain = import.meta.env.VITE_ROOT_DOMAIN;

  if (isOnPrivacyPage) return null;

  const onAccept = () => {
    Cookies.set('accepts_cookies', 'true', { domain: `.${rootDomain}`, expires: 365 });
    setAcceptedCookieExists('true');
    clarity.init(import.meta.env.VITE_CLARITY_ID);
    clarity.consent();
  };

  const onReject = () => {
    Cookies.set('accepts_cookies', 'false', { domain: `.${rootDomain}`, expires: 365 });
    setAcceptedCookieExists('false');
    clarity.init(import.meta.env.VITE_CLARITY_ID);
  };

  const privacyPolicyLink = marketingUrl ? `${marketingUrl}/privacy` : '/privacy';

  return (
    <>
      {!acceptedCookieExists && (
        <div>
          <div className="bg-color-inverse fixed top-0 z-[19] size-full opacity-80"></div>
          <div className="bg-color text-color border-1 absolute top-[2%] z-20 ml-[5%] w-[90%] justify-center gap-12 rounded-lg text-center md:ml-[35%] md:w-[30%]">
            <h3 className="bg-color-inverse text-color-inverse w-full rounded-t-lg p-4 ">EPC Builder Cookies 🍪</h3>
            <div className="p-4">
              <p className="mt-4">
                We use cookies to tailor your experience and optimize our site's performance. We would also like to use
                to cookies help us understand how you interact with our content so that we can enhance the services
                provided.
              </p>
              <p className="mt-4">By clicking accept, you agree to our use of these cookies.</p>
              <p className="mt-4">
                For more information about how this is used, please read our{' '}
                <a className="underline hover:opacity-70" href={privacyPolicyLink}>
                  privacy policy
                </a>
                .
              </p>
            </div>
            <div className="flex justify-center gap-8 p-4">
              <Button onClick={onAccept} style="secondary" className="max-w-[250px]">
                Accept Analytics Cookies
              </Button>
              <button className="underline hover:opacity-70" onClick={onReject}>
                Reject
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieModal;
