import React from 'react';
import { twMerge } from 'tailwind-merge';

export interface ISimpleProgressBarProps {
  progress: number; // % progress
  backgroundColor?: string;
  progressColor?: string;
  className?: string;
}

const SimpleProgressBar = ({
  progress,
  backgroundColor = '#dce1e3',
  progressColor = '#2a9c8e', // primary background color
  className,
}: ISimpleProgressBarProps) => {
  const baseClassName = 'w-full h-2';
  const finalClassName = twMerge(baseClassName, className);

  return (
    <div className={finalClassName} style={{ backgroundColor: backgroundColor }}>
      <div className="h-full" style={{ width: `${progress}%`, backgroundColor: progressColor }} />
    </div>
  );
};

export default SimpleProgressBar;
