import React from 'react';
import { BarLoader } from 'react-spinners';

const Loading = ({ id, color, className }: { id?: string; color?: string; className?: string }) => {
  return (
    <div id={id} className={`flex h-16 flex-row items-center justify-center ${className && className}`}>
      <BarLoader color={color || `#2a9c8e`} />
    </div>
  );
};

export default Loading;
