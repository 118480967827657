import React from 'react';

type TextAreaInputProps = JSX.IntrinsicElements['textarea'] & {
  className?: string;
  overrideBaseClassnames?: boolean;
  label?: string;
  error?: string;
};

export const TextAreaInput = React.forwardRef<HTMLTextAreaElement, TextAreaInputProps>((props, ref) => {
  const { label, error, name, className, overrideBaseClassnames, rows, ...rest } = props;

  const baseClassNames = `${className} text-color border-blue-lighter bg-color-secondary dark:border-primary-darker hover:shadow-grey-sm hover:border-blue-dark hover:dark:border-primary focus:dark:border-[#7AC2B9] focus:border-primary placeholder:text-neutral-dark w-full rounded-xl border-2 px-4 py-2 text-base outline-none`;
  return (
    <div className="relative flex w-full flex-col gap-2">
      {label && <p className="ml-2">{label}</p>}
      <textarea
        rows={rows}
        ref={ref}
        name={name}
        className={`no-scrollbar resize-none ${overrideBaseClassnames ? className : baseClassNames} ${!!error && 'border-error'}`}
        aria-invalid={!!error}
        {...rest}
      />
      {error && (
        <div id={`${name}-error`} className="ml-2 flex flex-col">
          <p className="text-error text-xs">{error}</p>
        </div>
      )}
    </div>
  );
});

TextAreaInput.displayName = 'TextAreaInput';

export default TextAreaInput;
