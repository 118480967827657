import { api } from '../utils';

export const getAddressesForPostcode = async ({ postcode }: { postcode: string }) => {
  return await api.get(`/loqate/find?postcode=${postcode.trim()}`);
};

export const getAddressRetrieve = async ({ id }: { id: string }) => {
  return await api.get(`/loqate/address/retrieve?id=${id}`);
};

export const getPropertyOwnerStatuses = async () => {
  return await api.get('/properties/owner-statuses');
};

export const getPropertyEpc = async ({ postcode, addressLine1 }: { postcode: string; addressLine1: string }) => {
  return await api.get(`/properties/epc-certificate?postcode=${postcode}&addressLine1=${addressLine1}`);
};
