import { ApplicationInsights } from '@microsoft/applicationinsights-web';

type AppInsightsConfig = {
  instrumentationKey: string;
};

class AppInsightsManager {
  private static instance: AppInsightsManager | null = null;
  private appInsights: ApplicationInsights | null = null;

  private constructor() {}

  public static getInstance(): AppInsightsManager {
    if (!AppInsightsManager.instance) {
      AppInsightsManager.instance = new AppInsightsManager();
    }
    return AppInsightsManager.instance;
  }

  public initialize(config: AppInsightsConfig): void {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: config.instrumentationKey,
      },
    });
    this.appInsights.loadAppInsights();
  }

  public getAppInsights(): ApplicationInsights | null {
    return this.appInsights;
  }
}
const appInsightsManager = AppInsightsManager.getInstance();

export { appInsightsManager };

export const initializeAppInsights = (config: AppInsightsConfig): void => {
  appInsightsManager.initialize(config);
};
