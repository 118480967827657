import { useEffect } from 'react';

const usePreloadImage = (imageUrl: string) => {
  useEffect(() => {
    const isDevelopment = process.env.NODE_ENV !== 'development';

    const basePath = imageUrl.replace(/\.[^/.]+$/, '');

    const preload = (src: string) => {
      const img = new Image();
      img.src = src;
    };

    preload(imageUrl);

    if (!isDevelopment) {
      preload(`${basePath}.webp`);
    }
  }, [imageUrl]);
};

export default usePreloadImage;
