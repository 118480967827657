import { clarity } from 'react-microsoft-clarity';
import Cookies from 'js-cookie';

const useClarity = () => {
  const acceptedCookieExists = Cookies.get('accepts_cookies');

  if (acceptedCookieExists?.toLowerCase() === 'true') {
    clarity.init(import.meta.env.VITE_CLARITY_ID);
  }
};

export default useClarity;
