export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const EMAIL_REGEX = /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\s*$/i;
export const POSTCODE_REGEX = /^\s*[a-z]{1,2}\d[a-z\d]?(?:\s*\d[a-z]{2}\s*)?$/i;
export const PHONE_REGEX = /^(?:\+44|0)\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d$/;

export type Option<T> = {
  label: string;
  value: T;
};

export type EnumType<T> = Record<string, T>;

export const enumToKeyValueArray = <T>(enumObject: EnumType<T>): Option<T>[] =>
  Object.keys(enumObject)
    .filter((key) => isNaN(Number(key)))
    .map((key) => {
      const formattedKey = String(key)
        .split('_')
        .map((word) => (word === 'EPC' ? 'EPC' : capitalize(word)))
        .join(' ');
      return { value: enumObject[key], label: formattedKey };
    });

export const getOptionByValue = <T>(options: Option<T>[], value: T): Option<T> | undefined =>
  options.find((option) => option.value === value);

export type Children = string | JSX.Element | false | undefined | (JSX.Element | JSX.Element[] | false | undefined)[];

export const isJSONString = (string: string) => {
  try {
    JSON.parse(string);
    return true;
  } catch (error) {
    return false;
  }
};
