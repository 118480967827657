import React from 'react';

export const ColorfulHorizontalLine = ({
  width = '100%',
  height = '0.125rem',
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <div className={`flex flex-row`} style={{ width, height }}>
      <div className="from-secondary-dark via-secondary to-secondary-light h-full flex-1 bg-gradient-to-r" />
      <div className="from-secondary-light via-primary to-primary-dark h-full flex-1 bg-gradient-to-r" />
    </div>
  );
};

export const HorizontalLine = () => {
  return (
    <div className="from-primary-dark via-primary via-secondary via-secondary-light to-secondary-dark h-1 w-full bg-gradient-to-r" />
  );
};

export const VerticalLine = () => {
  return (
    <div className="from-primary-dark via-primary via-secondary via-secondary-light to-secondary-dark h-full w-1 bg-gradient-to-b" />
  );
};
