import React from 'react';

type ImageProps = Omit<JSX.IntrinsicElements['img'], 'src'> & { src: string };

const Image = (props: ImageProps) => {
  const { src, alt, ...rest } = props;
  const isDevelopment = process.env.NODE_ENV === 'development';

  const basePath = src.replace(/\.[^/.]+$/, '');

  return (
    <picture>
      {!isDevelopment && <source srcSet={`${basePath}.webp`} type="image/webp" />}
      <img src={src} alt={alt || 'EPC Builder'} {...rest} />
    </picture>
  );
};

export default Image;
