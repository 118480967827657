'use client';

import React from 'react';
import { Theme, ToastContainer } from 'react-toastify';
import { Children } from '../utils';
import 'react-toastify/dist/ReactToastify.css';

export const ToastProvider = ({ theme, children }: { theme: string; children: Children }) => {
  return (
    <>
      {children}
      <ToastContainer position="bottom-center" theme={theme as Theme} />
    </>
  );
};
